* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #001f23;
  -webkit-font-smoothing: antialiased;
  font-family: "A Pompadour W05 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: relative;

  max-width: 1200px;
  margin: 0 auto;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.logo {
  width: 35px;
  height: 30px;
  object-fit: cover;
}

.header div:nth-of-type(1) {
  z-index: 2;
}

/* HAMBURGER STYLES */

.hamburger-icon {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  height: 40px;
}

.hamburger-icon > * {
  width: 40px;
  height: 3.5px;
  background-color: #17e2ad;
  transition: all 0.5s;
}

.bar-second {
  margin: 5px 0;
  width: 33px;
}

.bar-third {
  width: 25px;
}

.cross > * {
  width: 40px;
  transition: all 0.4s;
}

.cross div:nth-of-type(1) {
  position: relative;
  top: 3.5px;
  transform: rotate(225deg);
  transition: all 0.5s;
}

.cross div:nth-of-type(2) {
  opacity: 0;
  display: none;
  transition: all 0.5s;
}

.cross div:nth-of-type(3) {
  transform: rotate(-225deg);
  transition: all 0.5s;
}

/* HEADER SECTION */

.header {
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  height: 10vh;
  background-color: #001f23;

  font-size: 20px;
  width: 100%;
  margin: 0 auto;
  transition: top 0.5s;
  color: #82f8ce;

  position: sticky;
  top: 0;
  transition: all 1s;
  z-index: 5;
  padding: 50px 0;
}

.translucent {
  display: none;
}

.header ul {
  display: flex;
  list-style-type: none;
}

.header li a {
  margin-right: 15px;
  width: 90px;
  padding: 3px;
  text-align: center;
  font-size: 20px;
  color: #17e2ad;
  letter-spacing: 0.8px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Madimi One", sans-serif;
}

.header li:hover {
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
}

.menu > li a > span:nth-of-type(1) {
  color: #17e2ad;
  margin-right: 2px;
}

.menu > li a {
  color: #e4fff8;
  font-size: 15px;
  margin-right: 15px;
}

.menu li:nth-of-type(5) a {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      90deg,
      rgb(194, 249, 115) 0%,
      rgb(141, 243, 255) 12%,
      rgb(203, 195, 217) 20%,
      rgb(203, 195, 217) 26%,
      rgb(153, 252, 186) 36%,
      rgb(89, 207, 239) 50%,
      rgb(194, 249, 115) 62%,
      rgb(141, 243, 255) 74%,
      rgb(203, 195, 217) 86%,
      rgb(203, 195, 217) 94%,
      rgb(89, 207, 239) 100%
    )
    15;
  animation: border 2s ease infinite;
  padding: 8px 10px;
  text-align: center;
}

.menu > li a:hover {
  color: #17e2ad;
}

.moveUp {
  top: -100%;
  transition: top 1.2s;
}

/* MAIN SECTION*/

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 130px 0;
  height: 90vh;
  font-family: sans-serif;
  width: 80%;
  margin: 0 auto;
}

.main div:nth-of-type(1) {
  margin-bottom: 5px;
  color: #e4fff8;
  display: flex;
  flex-direction: column;
}

.main div:nth-of-type(1) > span:nth-of-type(1) {
  color: #17e2ad;
  margin-bottom: 30px;
  font-family: "Madimi One", sans-serif;
}

.main div:nth-of-type(1) > span:nth-of-type(2) {
  font-size: 70px;
  font-weight: 800;
  /* font-family: 'Madimi One', sans-serif; */
}

.main div:nth-of-type(2) {
  font-size: 70px;
  margin-bottom: 30px;
  color: #89a49d;
  font-family: "Madimi One", sans-serif;
}

.main div:nth-of-type(3) {
  font-size: calc(16px + 20 * ((50vw - 320px) / 1320));
  margin-bottom: 50px;

  color: #17e2ad;

  letter-spacing: 0.024em;
}

.main div:nth-of-type(4) a {
  width: 175px;
  font-size: 20px;
  background-color: transparent;
  padding: 15px 15px;
  color: #17e2ad;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #17e2ad;
}

.mail-icon {
  font-size: 25px;
  background-color: transparent;
}

/* ABOUT SECTION */

.about-container {
  width: 840px;
  margin: 0 auto;
  min-height: 90vh;
  padding: 12vh 0;
  /* border: 1px solid red; */
}

.about-intro,
.experience-heading {
  margin-bottom: 50px;
  color: #e4fff8;
}

.about-intro p:nth-of-type(1),
.experience-heading {
  font-weight: bold;
  font-size: 40px;
  font-family: "Madimi One", sans-serif;
}

.about-intro p:nth-of-type(2) {
  color: #8893af;
}

.about {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #525e65;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.about-image {
  width: 300px;
  border-radius: 10px;
  background-color: #aadacd;
  display: block;
  object-fit: contain;
  overflow: hidden;
}

.about-image-mobile {
  display: none;
  width: 31.25%;
  height: 300px;
  border-radius: 10px;
  background-color: #80c4b2;
  object-fit: contain;
}

.about-description {
  margin-right: 20px;
  text-align: start;
  font-family: "A Pompadour W05 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #8893af;
  letter-spacing: 0.7px;

  width: 65%;
}

.about-description > div:nth-of-type(1),
.experience-content > div:nth-of-type(1) {
  font-size: 20px;
}

.about-description > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
}

.skills-container {
  margin-top: 30px;
}

.skills {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  font-weight: 900;
  font-size: 15px;
  font-family: "Madimi One", sans-serif;
}

.skills div:nth-of-type(2) {
  margin: 0 15px;
}

.skill-arrow {
  color: #17e2ad;
  font-size: 10px;
  margin-right: 10px;
}

.resume {
  width: 200px;
  font-size: 20px;
  background-color: #01282d;
  padding: 15px 15px;
  color: #17e2ad;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #17e2ad;
  margin-top: 35px;

  transition: background-color 0.5s ease;
}

.resume > a {
  color: #17e2ad;
  font-family: "Madimi One", sans-serif;
}

.resume:hover {
  background-color: #17e2ad;
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.resume:hover .download-cv-btn {
  color: #001f23;
}

/* @keyframes color-change {
  0% {
    background-color: #01282d;
    transform: ;
  }
  100% {
    background-color: #17e2ad;
    transform: ;
  }
} */

/* Experience Section */
.experience-container {
  width: 840px;
  margin: 0 auto;
  min-height: 90vh;
  padding: 12vh 0;
}

.experience-section {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.experience-companies {
  display: flex;
  flex-direction: column;
}

.experience-companies > button {
  background-color: transparent;
  border: none;
  color: #525e65;
  width: 100px;
  height: 50px;

  text-align: left;
  padding-left: 20px;
}

.experience-companies > button:hover {
  background-color: #01282d;
  color: #17e2ad;
}

.experience-content {
  text-align: start;
  font-family: "A Pompadour W05 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #8893af;
  letter-spacing: 0.7px;
}

.experience-content > div:nth-of-type(1) {
  font-size: 30px;
  margin-bottom: 30px;
}

.experience-work {
  font-size: 20px;
  margin-top: 20px;
}

/* PROJECT SECTION */

/* .projects::before {
  content: 'Some Of My Implementations';
  display: block;
  position: absolute;
  height: 10vh;
  width: 100%;
} */

.project-heading {
  color: #e4fff8;
  width: 80%;
  margin: 50px auto;
  font-family: "Madimi One", sans-serif;
}

h1 > span:nth-of-type(1),
p > span:nth-of-type(1) {
  color: #17e2ad;
}

.project-heading::after,
.experience-heading::after {
  content: "";
  display: block;
  background-color: #aeb3be;
  height: 2px;
  width: 5%;
}

.projects {
  display: flex;
  justify-content: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.project-images {
  position: sticky;
  top: 28%;
  height: 350px;
  width: 40%;
  margin-right: 5%;
  border-radius: 40px;
}

.project-images > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  opacity: 0;
  transform: translate(0px, 0px);
  scale: (1, 1);

  transition: opacity 0.5s linear;
}

.project-description {
  width: 40%;
}

.project-description > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.project-description div div:nth-of-type(1),
.project-description div div:nth-of-type(2),
.project-description div div:nth-of-type(4) {
  color: #90f5d0;
}

.project-description div div:nth-of-type(1) {
  line-height: 116%;
  letter-spacing: 0.02em;
  font-family: "Madimi One", sans-serif;

  /* font-family: "A Pompadour Bold W00 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif; */
  font-size: calc(24px + 14 * ((100vw - 320px) / 1320));
}

.project-description div div:nth-of-type(2) {
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
}

.project-description div div:nth-of-type(2) div:nth-of-type(1) {
  margin: 0;
  line-height: 100%;
  letter-spacing: -0.01em;
  font-family: "A Pompadour Bold W00 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin-left: -4px;
  margin-top: 16px;
  margin-bottom: -15px;
  font-size: calc(68px + 68 * ((100vw - 320px) / 1320));
}

.project-description div div:nth-of-type(2) div:nth-of-type(2) {
  border: 1px solid #17e2ad;
  border-radius: 5px;
  width: 100px;
  font-size: 25px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  margin-left: 40px;
}

.project-description div div:nth-of-type(2) div:nth-of-type(2) > a {
  color: #17e2ad;
}

.project-description div div:nth-of-type(2) div:nth-of-type(2) > a:hover {
  color: rgb(240, 234, 234);
}

.project-description div div:nth-of-type(4) {
  font-size: calc(17px + 5 * ((100vw - 320px) / 1320));
  font-family: "A Pompadour Bold W00 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin: 0;
  letter-spacing: 0.04em;
  color: #82f8ce;
}

.project-description div div:nth-of-type(3) {
  color: #90f4d0;
  font-size: calc(17px + 7 * ((100vw - 320px) / 1320));
  line-height: 124%;
  letter-spacing: 0.035em;
  margin: 24px 0 24px;
  opacity: 0.86;
  font-family: "A Pompadour W05 Regular", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.responsive_project_image {
  display: none;
}

/* FOOTER SECTION */
.footer {
  color: #90f5d0;
  padding: 50px 68px;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  /* border: 1px solid red; */
  height: 30vh;
}

.footer div:nth-of-type(1)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-top: -1px;
  height: 0.75px;
  display: block;
  background-image: linear-gradient(
    90deg,
    rgb(194, 249, 115) 0%,
    rgb(141, 243, 255) 12%,
    rgb(203, 195, 217) 20%,
    rgb(203, 195, 217) 26%,
    rgb(153, 252, 186) 36%,
    rgb(89, 207, 239) 50%,
    rgb(194, 249, 115) 62%,
    rgb(141, 243, 255) 74%,
    rgb(203, 195, 217) 86%,
    rgb(203, 195, 217) 94%,
    rgb(89, 207, 239) 100%
  );
  animation: gradient 15s ease infinite;
  background-size: 200% 200%;
}

.footer div:nth-of-type(2) div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
}

.footer div:nth-of-type(2) div:nth-of-type(1) > a {
  color: #82f8ce;
  font-size: 25px;
}
.footer-dev {
  margin-top: 16px;
  /* border: 1px solid red; */
  /* width: 200px */
}
/* DESKTOP VIEW */
@media only screen and (max-width: 3441px) and (min-width: 1801px) {
  .project-description > div {
    height: 80vh;
  }

  .project-images {
    border: 1px solid red;
  }

  .project-description > div:nth-of-type(4) {
    height: 900px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1900px) {
  .project-description > div {
    height: 1200vh;
  }

  .project-description > div:nth-of-type(1) {
    height: 110vh;
  }

  .project-description > div:nth-of-type(2) {
    /* border: 1px solid red; */
    height: 90vh;
  }

  .project-description > div:nth-of-type(3) {
    height: 100vh;
  }

  .project-description > div:nth-of-type(4) {
    height: 57vh;
  }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {
  /* ABOUT SECTION */

  .about-container {
    width: 90%;
    margin: 0 auto;
    height: auto;
  }

  .about-intro {
    padding: 0;
  }

  .about {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    color: #525e65;
    font-family: Georgia, "Times New Roman", Times, serif;
  }

  .about-image {
    /* display: none; */
    background-color: #e3e0f3;
    width: 240px;
    height: auto;
  }

  .about-image-mobile {
    width: 240px;
    height: auto;
    border-radius: 10px;
    background-color: #e3e0f3;
    margin-top: 30px;
    display: none;
  }

  .about-description {
    width: 100%;
    text-align: start;
    font-family: sans-serif;
  }

  .skills-container {
    margin-top: 30px;
  }

  .skills {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    font-weight: 800;
    font-size: 13px;
    font-family: "Courier New", Courier, monospace;
  }

  .skills > div:nth-of-type(2) {
    margin: 0 30px;
  }

  .skill-arrow {
    color: #6c55e0;
    font-size: 8px;
    margin-right: 5px;
  }

  .project-description > div {
    height: 100vh;
  }

  .project-description > div {
    /* border: 1px solid red; */
    display: flex;
  }
  .experience-container {
    width: 90%;
  }
}

/* MOBILE VIEW */

@media only screen and (max-width: 600px) {
  /* HAMBURGER SECTION */
  .header {
    padding: 50px 0;
    width: 95%;
  }

  .hamburger-icon {
    display: flex;
  }

  .drawing-menu {
    display: flex;
    position: fixed;
    height: 100vh;
    right: -100%;
    top: 0;
    transition: all 0.5s;
  }

  .translucent {
    display: block;
    height: 100%;
    width: 30vw;
    opacity: 0.5;
    background-color: #374844;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 70vw;
    background-color: #01282d;
    padding-top: 15vh;
    transition: all 0.5s;
  }

  .menu > li {
    width: 100%;
    margin-right: 0;
  }

  .menu > li:hover {
    color: #404a4f;
  }

  .menu > * {
    margin-bottom: 30px;
    color: whitesmoke;
    text-align: center;
  }

  .menu * a {
    color: whitesmoke;
  }

  .menu-toggle {
    right: 0;
    transition: all 0.5s;
  }

  .cross {
    padding-top: 10px;
  }

  .cross > * {
    z-index: 2;
    background-color: white;
  }

  /* MAIN SECTION */
  .main {
    width: 90%;
    margin: 0 auto;
  }

  /* ABOUT SECTION */

  .about-container {
    width: 90%;
    margin: 150px auto 0 auto;
    min-height: 100vh;
  }

  .about-intro {
    padding: 0;
    /* border: 1px solid red; */
    margin-top: 50px;
  }

  .about {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    color: #525e65;
    font-family: Georgia, "Times New Roman", Times, serif;
  }

  .about-image {
    display: none;
  }

  .about-image-mobile {
    width: 240px;
    height: auto;
    border-radius: 10px;
    background-color: #e3e0f3;
    margin-top: 30px;
    display: block;
  }

  .about-description {
    width: 100%;
    text-align: start;
    font-family: sans-serif;
  }

  .skills-container {
    margin-top: 30px;
  }

  .skills {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    font-weight: 800;
    font-size: 13px;
    font-family: "Courier New", Courier, monospace;
  }

  .skills > div:nth-of-type(2) {
    margin: 0 30px;
  }

  .skill-arrow {
    color: #6c55e0;
    font-size: 8px;
    margin-right: 5px;
  }

  .experience-container {
    width: 90%;
  }

  /* Experience section */
  .experience-container {
    width: 95%;
    margin: 0 auto;
    padding: 0;
  }

  .experience-section {
    display: flex;
    flex-direction: column;
    height: 95vh;
    gap: 5%;
    padding: 20px;
  }

  .experience-companies {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  /* PROJECT SECTION */

  .project-heading,
  .experience-heading {
    width: 90%;
    font-weight: bold;
    font-size: 37px;
  }

  .experience-heading ::after {
    content: "";
    display: block;
    background-color: #aeb3be;
    height: 1px;
    width: 60%;
  }

  .project-heading::after {
    content: "";
    display: block;
    background-color: #aeb3be;
    height: 1px;
    width: 80%;
  }

  .projects {
    margin-bottom: 0;
  }

  .project-images {
    display: none;
  }

  .project-description {
    width: 100%;
  }

  .project-description > div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .responsive_project_image {
    display: block;
    border-radius: 20px;
    height: 30%;
    width: 80%;
    margin: 30px 0 12px 0;
  }

  .project-description div div:nth-of-type(2) div:nth-of-type(1) {
    margin-bottom: -5px;
  }

  .project-description div div:nth-of-type(2) div:nth-of-type(2) {
    width: 90px;
    font-size: 20px;
    padding: 10px 10px 4px 10px;
    height: 45px;
    margin-left: 30px;
  }

  .project-description div div:nth-of-type(2) div:nth-of-type(2) a {
    font-size: 25px;
  }

  /* FOOTER */

  .footer {
    height: 15vh;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .footer div:nth-of-type(1) {
    order: 2;
    padding-bottom: 10px;
  }

  .footer div:nth-of-type(2) > div:nth-of-type(1) {
    margin-bottom: 15px;
    width: 100%;
  }

  .footer div:nth-of-type(2) {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer div:nth-of-type(2) > div:nth-of-type(2) {
    order: 2;
  }

  .footer div:nth-of-type(2) > div:nth-of-type(1) a {
    color: rgb(251, 246, 246);
    font-size: 35px;
  }
}

@media only screen and (max-width: 290px) {
  .about-container {
    margin-top: 300px;
  }
}
